// JavaScript
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyAWKBpEChD0mjFpbngir12RlRPnZPetYOo",
    authDomain: "magneticforce-fd22d.firebaseapp.com",
    projectId: "magneticforce-fd22d",
    storageBucket: "magneticforce-fd22d.appspot.com",
    messagingSenderId: "296946539182",
    appId: "1:296946539182:web:14c34bfb5c56d2eaaf2b3f",
    measurementId: "G-S83CXZF057"
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
export {db}
