<section id="music" class="products-area pt-100 pb-70 st-change" style="padding-top: 14px !important">
    <div class="container">
        <div class="section-title farfle full-width">
            <h2> Music</h2>
            <!-- <p>Music composed and produced by Magnetic Force LLC.</p> -->
            <div class="audio-holder">
               <img class="audio-ico" src="assets/img/vinyl_front.gif" alt="image">
               <div class="audio-text">
                   <div>
                        <h4>"Godlings Theme"</h4>
                        <audio controls>
                            <source src="assets/vid/GodlingsThemeSong.mp3" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                   </div>

                   <div class="audi-sec">
                    <h4>"Godlings Theme" - With Narration</h4>
                    <audio controls>
                        <source src="assets/vid/GodlingsThemeWithNarration.mp3" type="audio/mpeg">
                        Your browser does not support the audio element.
                    </audio>
               </div>


                </div>
                <div class="audio-bg">
                    <p class="credits">
                        <strong>Credits:</strong><br />
                        Composer- Mark Sadka<br />
                        Narrator- Brianna Wanlass<br />
                        Sound Effects- Dan Bucknell<br />
                        Executive Producer- Percy L Carey Jr
                    </p>
                    <p class="credits">
                        <strong>Running Time</strong><br />
                        Godlings Theme (with narration): 1m 36s - <a href="assets/vid/GodlingsThemeWithNarration.mp3">Download Now</a><br />
                        Godlings Theme (Instrumental): 1m 34s - <a href="assets/vid/GodlingsThemeSong.mp3">Download Now</a>
                    </p>
                </div>
            </div>
        </div>
    </div>

</section>
