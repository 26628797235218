<section id="store-section" class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Store</h2>
            <p class="inship"><strong>Note:</strong> International shipping fee is $40 on all items</p>
            <div class="search-btn-box shopcar">
                <a href="#" class="default-btn butchng" data-bs-toggle="modal" data-bs-target="#contactModal"> <i class="fa-solid fa-cart-shopping"></i><span style="padding-left: 4px">{{shoppingCartBaseLine.length}} Items in cart</span></a>
            </div>
        </div>

        <div class="store-container"  *ngFor="let title of fullProductArrayNew">
            <h4>
                {{ title.name }} - <span class="subhead">{{ title.type }}</span>
            </h4>
            <div class="product-list">
                <div [class]="title.size + ' product'" *ngFor="let product of title.products">
                    <div class="floating-text" [ngClass]="product.version === 'Digital' ? 'digi' : '' || product.version === 'Kindle' ? 'kindle' : ''">{{product.version}}</div>
                    <img class="item-img" src="assets/img/{{product.imgSource}}" alt="image">
                    <div class="product-details">
                        <span class="version">{{product.version}}</span>
                        <div class="desc">{{product.description}}</div>
                        <div class="price">${{product.price}}<span class="ship" *ngIf="product.sh"> +${{product.sh | number : '1.2-2'}} S/H</span></div>
                        <span *ngIf="product.inventoryCount === 0" class="btn buy disabled" disabled>Sold Out</span>
                        <input type="number" [(ngModel)]="product.qty" id="qty" name="qty" min="0" max="{{product.inventoryCount}}" class="quantity-update" placeholder="Qty" *ngIf="product.version !== 'Digital' && product.version !== 'Kindle'">
                        <span (click)="addToCart(product, title.name)"  *ngIf="product.inventoryCount !== 0 && product.version !== 'Kindle'" class="btn buy">Add to cart</span>
                        <a href="{{product.kindleLink }}" target="_blank" *ngIf="product.version === 'Kindle'" class="btn buy">Visit Amazon</a>
                    </div>
                </div>
            </div>




            <!-- <div [class]="product.size + 'store-item'" >
                <h4>{{ product.name }}</h4>
                <div class="details">
                    <img class="item-img" src="assets/img/{{product.imgSource}}" alt="image">
                    <span class="caption2" *ngIf="product.type != ''">{{ product.type }}</span>
                    <div class="price" *ngIf="product.priceHard >= 1"><strong>Hardcover:</strong> ${{product.priceHard}} <span class="ship">+S&H</span> <input type="number" [(ngModel)]="product.qtyHard" id="qtyHard" name="qtyHard" min="0" max="{{product.inventoryCount}}" class="quantity-update sminput" placeholder="Qty"></div>
                    <div class="price" *ngIf="product.priceSoft >= 1"><strong>Softcover:</strong> ${{product.priceSoft}} <input type="number" [(ngModel)]="product.qtySoft" id="qtySoft" name="qtySoft" min="0" max="{{product.inventoryCount}}" class="quantity-update sminput" placeholder="Qty"></div>
                    <div class="price" *ngIf="product.price >= 1"><strong>Physical:</strong> ${{ product.price }} <span class="ship">+S&H</span> <input type="number" [(ngModel)]="product.qty" *ngIf="product.inventoryCount !== 0 && product.priceHard == 0" id="quantity" name="quantity" min="0" max="{{product.inventoryCount}}" class="quantity-update" placeholder="Qty"></div>
                    <div class="price" *ngIf="product.digital >= 1"><strong>Digital:</strong> ${{product.digital}} <input type="number" [(ngModel)]="product.qtyDigital" id="digital" name="digital" min="0" max="1" class="quantity-update sminput" placeholder="Qty"></div>
                    <p class="status" *ngIf="product.inventoryCount === 0">SOLD OUT</p>


                    <span *ngIf="product.inventoryCount === 0" class="btn buy disabled" disabled>Sold Out</span>
                    <span (click)="addToCart(product, product.qty)"  *ngIf="product.inventoryCount !== 0" class="btn buy">Add to cart</span>
                </div>
            </div> -->
        </div>



    </div>

    <!-- <div class="gallery-shape1"><img src="assets/img/sleepertran.png" alt="image"></div> -->
</section>
