<section id="collectables" class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Collectibles</h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p> -->
        </div>
        <div class="collectable-container">
            <div class="collectable-item">
                <a href="#art-popup" class="link-btn art-popup">
                    <img src="assets/img/godlingcollect.jpg" alt="image">
                    <div class="collectable-status">GODLINGS</div>
                    <div class="collectable-label">ATET Replica</div>
                </a>
                <div id="art-popup" class="image-popup mfp-hide">
                    <img src="assets/img/godlingcollect.jpg" alt="image">
                </div>
            </div>
            <div class="collectable-item">
                <a href="#art-popup2" class="link-btn art-popup">
                    <img src="assets/img/sg-cards.jpg" alt="image">
                    <div class="collectable-status">SARA GRAY CHRONICLES VOL 1</div>
                    <div class="collectable-label">Trading Cards</div>
                </a>
                <div id="art-popup2" class="image-popup mfp-hide">
                    <img src="assets/img/sg-cards.jpg" alt="image">
                </div>
            </div>
            <div class="collectable-item">
                <a href="#art-popup3" class="link-btn art-popup">
                    <img src="assets/img/GodlingsCollectablefigures4.gif" alt="image">
                    <div class="collectable-status">GODLINGS</div>
                    <div class="collectable-label">Character Figures</div>
                </a>
                <div id="art-popup3" class="image-popup mfp-hide">
                    <img src="assets/img/GodlingsCollectablefigures4.gif" alt="image">
                </div>
            </div>
            <div class="collectable-item">
                <a href="#art-popup4" class="link-btn art-popup">
                    <img src="assets/img/gl-cards.jpg" alt="image">
                    <div class="collectable-status">GODLINGS</div>
                    <div class="collectable-label">Trading Cards</div>
                </a>
                <div id="art-popup4" class="image-popup mfp-hide">
                    <img src="assets/img/gl-cards.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="gallery-shape1"><img src="assets/img/sleepertran.png" alt="image"></div> -->
</section>
