<ngx-ui-loader></ngx-ui-loader>
<app-main-banner></app-main-banner>
<app-next-match></app-next-match>
<app-upcoming-matches></app-upcoming-matches>
<!-- <app-news></app-news> -->
<app-matches-highlights></app-matches-highlights>
<app-partners></app-partners>
<app-products></app-products>
<!-- <app-nft></app-nft> -->
<app-gallery></app-gallery>
<app-store></app-store>
<!-- <app-subscribe></app-subscribe> -->
<app-blog></app-blog>
