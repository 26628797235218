import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  // observing array for upcoming notifications
  private shoppingCart = new BehaviorSubject<any>([]);
  upcomingshoppingCartHandler = this.shoppingCart.asObservable();

  private beatDisplay = new BehaviorSubject<any>([]);
  upcomingbeatDisplay = this.beatDisplay.asObservable();

  constructor() {

  }
  // handle unread notification array
  updateShoppingCart(value: any) {
    this.shoppingCart.next(value);
  }
  // handle unread notification array
  updateBeatlisting(value: any) {
    this.beatDisplay.next(value);
  }

}
