import { Component, OnInit } from '@angular/core';
import { CartService } from '../../../services/cart.service'
import {Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    currentCart = [];
    subscriptionCart: Subscription;

    constructor(private cartService: CartService) {
        this.subscriptionCart = cartService.upcomingshoppingCartHandler.subscribe(response => {
            let tempCart = [];
            this.currentCart = response;
        });
     }

  ngOnInit(): void {
  }

}
