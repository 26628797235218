import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-next-match',
  templateUrl: './next-match.component.html',
  styleUrls: ['./next-match.component.scss']
})
export class NextMatchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  navigateToSG() {
    window.open(
        'https://saragray.io/',
        '_blank' // <- This is what makes it open in a new window.
    );
  }
}
