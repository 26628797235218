<section class="next-match-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-md-12"></div>
            <div class="col-lg-4 col-md-12">
                <div class="first-match-image">
                    <div class="holder-match-div"></div>
                    <div class="row align-items-center adjust-float">

                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="next-match-image">
                    <!-- <img src="assets/img/godlings-newbanner.jpg" alt="image"> -->
                </div>
            </div>
        </div>
    </div>
</section>
