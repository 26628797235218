import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
    displayModal = false;
  constructor() { }

  ngOnInit(): void {
  }

  showModalDialog() {
    this.displayModal = true;
}

}
