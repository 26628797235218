<section id="news" class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Latest News</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <img src="assets/img/blog/img1.jpg" alt="image">
                        <a routerLink="/blog-details" class="link-btn"></a>
                    </div>

                    <div class="post-content">
                        <ul class="post-meta">
                            <li><a routerLink="/">Headling</a></li>
                            <li>June 10, 2022</li>
                        </ul>
                        <h3><a routerLink="/blog-details">Lorem ipsum dolor sit amet</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <img src="assets/img/blog/img2.jpg" alt="image">
                        <a routerLink="/blog-details" class="link-btn"></a>
                    </div>

                    <div class="post-content">
                        <ul class="post-meta">
                            <li><a routerLink="/">Headline</a></li>
                            <li>June 8, 2022</li>
                        </ul>
                        <h3><a routerLink="/blog-details">Lorem ipsum dolor sit amet</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <div class="post-image">
                        <img src="assets/img/blog/img3.jpg" alt="image">
                        <a routerLink="/blog-details" class="link-btn"></a>
                    </div>

                    <div class="post-content">
                        <ul class="post-meta">
                            <li><a routerLink="/">Subtitle</a></li>
                            <li>June 14, 2022</li>
                        </ul>
                        <h3><a routerLink="/blog-details">Lorem ipsum dolor sit amet</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
