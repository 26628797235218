<div id="home" class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <mdb-carousel [controls]="true" [indicators]="true">
                <mdb-carousel-item>
                  <img
                    src="../../../../assets/img/banner1.jpg"
                    class="d-block w-100"
                    alt="..."
                  />
                </mdb-carousel-item>
                <mdb-carousel-item>
                  <img
                    src="../../../../assets/img/banner2.jpg"
                    class="d-block w-100"
                    alt="..."
                  />
                </mdb-carousel-item>
                <mdb-carousel-item>
                  <img
                    src="../../../../assets/img/banner3.jpg"
                    class="d-block w-100"
                    alt="..."
                  />
                </mdb-carousel-item>
                <mdb-carousel-item>
                    <img
                      src="../../../../assets/img/banner4.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </mdb-carousel-item>
                  <mdb-carousel-item>
                    <img
                      src="../../../../assets/img/banner5.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </mdb-carousel-item>
                  <mdb-carousel-item>
                    <img
                      src="../../../../assets/img/banner6.png"
                      class="d-block w-100"
                      alt="..."
                    />
                  </mdb-carousel-item>
              </mdb-carousel>
            <!-- <div class="container-fluid">

            </div> -->
        </div>
    </div>
    <!-- <div class="main-banner-content">

    </div> -->
    <div class="banner-footer-content">
        <div class="container-fluid">
            <img class="distort" src="../../../../assets/img/patchlogo.gif">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <a href="mailto:info@magneticforce.com" class="email-link">Magnetic Force - “Attract The Best”</a>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul class="social">
                        <li><a href="https://www.facebook.com/MagneticForceLLC" target="_blank"><i class="fa-brands fa-facebook-square"></i></a></li>
                        <li><a href="https://www.instagram.com/magneticforcellc" target="_blank"><i class="fa-brands fa-instagram-square"></i></a></li>
                        <li><a href="https://twitter.com/MagneticLLC" target="_blank"><i class="fa-brands fa-twitter-square"></i></a></li>
                        <li><a href="https://www.twitch.tv/magneticforcellc" target="_blank"><i class="fa-brands fa-twitch"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCBmJshtS0RyL53rxnsEAYzg/about" target="_blank"><i class="fa-brands fa-youtube-square"></i></a></li>
                        <li><a href="https://magneticforcellc.tumblr.com/" target="_blank"><i class="fa-brands fa-tumblr-square"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/magnetic-force-llc" target="_blank"><i class="fa-brands fa-linkedin"></i></a></li>
                        <li><a href="https://www.tiktok.com/@magneticforcellc" target="_blank"><i class="fa-brands fa-tiktok font-y"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
