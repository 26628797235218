<nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
        <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.jpg" alt="image" style="height: 90px"></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <div class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav active">
                <li class="nav-item"><a class="nav-link" href="#home">Home</a></li>
                <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                <li class="nav-item"><a class="nav-link" href="#videos">Videos</a></li>
                <li class="nav-item"><a class="nav-link" href="#books">Books</a></li>
                <li class="nav-item"><a class="nav-link" href="#music">Music</a></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#nft">NFT/Gaming</a></li> -->
                <li class="nav-item"><a class="nav-link" href="#collectables">Collectibles</a></li>
                <li class="nav-item"><a class="nav-link" href="#store-section">Store</a></li>
                <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
                <!-- <li class="nav-item"><a class="nav-link" routerLink="/signup">Signup</a></li> -->
            </ul>
            <div class="others-option">
                <div class="option-item">
                    <div class="search-btn-box">
                        <a href="#" class="default-btn" data-bs-toggle="modal" data-bs-target="#contactModal"><i class="fa-solid fa-cart-shopping"></i><span style="padding-left: 4px">{{currentCart.length}}</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
