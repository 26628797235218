<section id="about" class="upcoming-matches-area pt-100 pb-70">
    <div class="container">
        <div class="section-title abt-section">
            <h2>About</h2>
            <p class="bordered">
                Magnetic Force, LLC is a multimedia company wholly funded by private capital. We specialize in brand entertainment implementation. We use a cross-platform campaign approach, meaning that our signature branded content is exploited across media, including via streaming, television, film, music, books, games, and influencer integration.
                <br /><br />
                Our portfolio of intellectual property includes a universe of characters and stories that we anticipate will resonate strongly with a range of target markets around the world. With story as our main driver, we are developing world-class creative talent that will become the next household name on your favorite entertainment platform.
            </p>
            <div class="founder">
                <div class="founder-bio">
                    <h4>PERCY CAREY (Founder)</h4>
                    <p>Percy Carey is an Eisner Award-nominated comic book writer from New York City. Percy has worked with DC Comics, Complex Magazine, MTV Splash Page, and USA Today.</p>
                </div>
                <img src="assets/img/PercyCareyFounder.jpeg" alt="image" class="founder-ico">
            </div>
        </div>


        <!-- <div class="upcoming-matches-slides owl-carousel owl-theme">
            <div class="single-upcoming-matches-item">
                <div class="date">
                    <span>BOOKS</span>
                </div>
                <h3>Graphic Novels</h3>
                <span class="sub-title">Sleeper / The Godlings</span>
                <a href="#" class="default-btn">Read More</a>
            </div>

            <div class="single-upcoming-matches-item">
                <div class="date">
                    <span>MUSIC</span>
                </div>
                <h3>New Albums</h3>
                <span class="sub-title">MF GRIM</span>
                <a href="#" class="default-btn">Read More</a>
            </div>

            <div class="single-upcoming-matches-item">
                <div class="date">
                    <span>NFT/Gaming</span>
                </div>
                <h3>Digital collectables and other stuff.</h3>
                <span class="sub-title">La Liga</span>
                <a href="#" class="default-btn">Read More</a>
            </div>
        </div> -->
    </div>

    <div class="upcoming-matches-shape1"><img src="assets/img/dionysustshirt.png" alt="image" class="dynimage"></div>
</section>
