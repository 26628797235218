// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // paypalkey: "AQha3V53nyv9fBiSCuVgvml6YDPcrGz2jpmdVNQewAUM7O1hUT9WuSwhiA2eIYCPDK1vJveeyMVbqtw9" // Magnetic Force Sandbox
    paypalkey: "AZ1wRTSE8Lm-IWkoPW0YDHr8IJBNZjNONRprPIxlVayPwct-_2ErqbFmhPxN63oS5Cy2HHyy-BdVViFu" // Magnetic Force Live
  };

  export const digitalDownloads = [
    {
        name: 'SLEEPER: THE SARA GRAY CHRONICLES VOL 1',
        source: 'SleepercgsEayjOlQL512c.pdf',
    },
    {
        name: 'GODLINGS AND THE GATES OF CHAOS',
        source: 'Godlings_GraphicNovelYN1L7S1MtfZdVS0.pdf',
    },
    {
        name: 'GODLINGS AND THE GATES OF CHAOS: The Cave',
        source: 'Godlings_Part1_TheCaveWwxGE759vuyrrkW.pdf',
    },
    {
        name: 'GODLINGS AND THE GATES OF CHAOS: The Portal',
        source: 'Godlings_Part2_ThePortalmdXh0uJVhIdOqLe.pdf',
    },
    {
        name: 'GODLINGS AND THE GATES OF CHAOS: The Queen',
        source: 'Godlings_Part3_TheQueenR7BXBJvKKKOyC18.pdf',
    },
    {
        name: 'GODLINGS AND THE GATES OF CHAOS: The Chaos',
        source: 'Godlings_Part4_TheChaosrvjvZSWKdi5yaC9.pdf',
    }
]
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
