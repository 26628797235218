<section id="nft" class="partners-area bg-161616 pt-100 pb-70" style="padding-top: 0px; position: relative">
    <div class="container">
        <div class="section-title sec-title">
            <h2>NFT / Gaming</h2>
            <!-- <p>Releasing digital collectables, Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="row">
            <div class="col-lg-5 col-sm-4 col-md-4 col-6 mobile-gone"></div>

            <div class="col-lg-7 col-sm-4 col-md-4 col-6 containy">
                <div class="single-partners-box nft-text">
                    <p>
                        <strong>SLEEPER: THE SARA GRAY CHRONICLES BOOK 1</strong>
                        <br />Where shadowy orgainizations use teenage superspies to carry out their nefarious plans. If she's going to succeed in breaking away from the people who trained her, Sara will need all the help she can get. That's where you come in.
                    </p>
                    <div class="inner-cont">
                        <p>When you purchase the Sara Gray Chronicles Book 1, you will receive a set of collectible cards, and a Sara Gray-themed NFT. There are different tiers of NFT rarity, ranging from the Original cover to the "super rare" Gold signature edition, and all tokens are randomly minted. As an NFT holder will be the first to hear about the future of this exciting franchise.</p>
                        <img class="nft-img" src="assets/img/NFT_Softcoveroriginalx125rezi.gif" alt="image">
                    </div>

                    <!-- <p>Over the coming months, you will be given access to exclusive members-only content including sneak peaks of the next part of Sara's adventure.</p> -->
                    <a href="https://saragray.io" target="_blank" class="btn" type="button">Visit SaraGray.io for more info!</a>
                </div>
            </div>

            <!-- <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img4.png" alt="image">
                    </a>
                </div>
            </div> -->

            <!-- <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img5.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img6.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img7.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img8.png" alt="image">
                    </a>
                </div>
            </div> -->
        </div>
        <div class="nft-release-shape1 sharp"><img src="assets/img/sleepertran.png" alt="image" class="nemesis"></div>
    </div>
</section>
