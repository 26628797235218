import { Component, OnInit } from '@angular/core';
import { CartService } from '../../../services/cart.service'
import { Subject, Subscription } from 'rxjs';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { environment } from '../../../../environments/environment';
import { digitalDownloads } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { db } from '../../../firebase';
import { collection, getDocs, addDoc, doc, updateDoc } from "firebase/firestore";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public payPalConfig ? : IPayPalConfig;
  currentCart = [];
  subscriptionCart: Subscription;
  beatListing = [];
  subscriptionBeatList: Subscription;
  completed = false;
  showCancel = false;
  showError = false;
  showPaypal = false;
  paypalPurchaseInfo: any;
  formedDBPurchase = {};
  totallingCost = 0;
  paypalFormatItems = [];
  updateArray = [];
  docRef: any;
  originalProductsArray = [];
  downloadsList = [];
  status = 'cart';
  successEmailHtml = '';
  cartBackup = [];

  addressForm = new FormGroup({
    emailAddress: new FormControl('', Validators.required),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    international: new FormControl(false),
    address: new FormControl('', Validators.required),
    address2: new FormControl(''),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    postalCode: new FormControl('', [Validators.required]),
    country: new FormControl('')
  });

  hasUnitNumber = false;

  states = [
    {
        'name': 'Alabama',
        'abbreviation': 'AL'
    },
    {
        'name': 'Alaska',
        'abbreviation': 'AK'
    },
    {
        'name': 'American Samoa',
        'abbreviation': 'AS'
    },
    {
        'name': 'Arizona',
        'abbreviation': 'AZ'
    },
    {
        'name': 'Arkansas',
        'abbreviation': 'AR'
    },
    {
        'name': 'California',
        'abbreviation': 'CA'
    },
    {
        'name': 'Colorado',
        'abbreviation': 'CO'
    },
    {
        'name': 'Connecticut',
        'abbreviation': 'CT'
    },
    {
        'name': 'Delaware',
        'abbreviation': 'DE'
    },
    {
        'name': 'District Of Columbia',
        'abbreviation': 'DC'
    },
    {
        'name': 'Florida',
        'abbreviation': 'FL'
    },
    {
        'name': 'Georgia',
        'abbreviation': 'GA'
    },
    {
        'name': 'Hawaii',
        'abbreviation': 'HI'
    },
    {
        'name': 'Idaho',
        'abbreviation': 'ID'
    },
    {
        'name': 'Illinois',
        'abbreviation': 'IL'
    },
    {
        'name': 'Indiana',
        'abbreviation': 'IN'
    },
    {
        'name': 'Iowa',
        'abbreviation': 'IA'
    },
    {
        'name': 'Kansas',
        'abbreviation': 'KS'
    },
    {
        'name': 'Kentucky',
        'abbreviation': 'KY'
    },
    {
        'name': 'Louisiana',
        'abbreviation': 'LA'
    },
    {
        'name': 'Maine',
        'abbreviation': 'ME'
    },
    {
        'name': 'Maryland',
        'abbreviation': 'MD'
    },
    {
        'name': 'Massachusetts',
        'abbreviation': 'MA'
    },
    {
        'name': 'Michigan',
        'abbreviation': 'MI'
    },
    {
        'name': 'Minnesota',
        'abbreviation': 'MN'
    },
    {
        'name': 'Mississippi',
        'abbreviation': 'MS'
    },
    {
        'name': 'Missouri',
        'abbreviation': 'MO'
    },
    {
        'name': 'Montana',
        'abbreviation': 'MT'
    },
    {
        'name': 'Nebraska',
        'abbreviation': 'NE'
    },
    {
        'name': 'Nevada',
        'abbreviation': 'NV'
    },
    {
        'name': 'New Hampshire',
        'abbreviation': 'NH'
    },
    {
        'name': 'New Jersey',
        'abbreviation': 'NJ'
    },
    {
        'name': 'New Mexico',
        'abbreviation': 'NM'
    },
    {
        'name': 'New York',
        'abbreviation': 'NY'
    },
    {
        'name': 'North Carolina',
        'abbreviation': 'NC'
    },
    {
        'name': 'North Dakota',
        'abbreviation': 'ND'
    },
    {
        'name': 'Ohio',
        'abbreviation': 'OH'
    },
    {
        'name': 'Oklahoma',
        'abbreviation': 'OK'
    },
    {
        'name': 'Oregon',
        'abbreviation': 'OR'
    },
    {
        'name': 'Pennsylvania',
        'abbreviation': 'PA'
    },
    {
        'name': 'Rhode Island',
        'abbreviation': 'RI'
    },
    {
        'name': 'South Carolina',
        'abbreviation': 'SC'
    },
    {
        'name': 'South Dakota',
        'abbreviation': 'SD'
    },
    {
        'name': 'Tennessee',
        'abbreviation': 'TN'
    },
    {
        'name': 'Texas',
        'abbreviation': 'TX'
    },
    {
        'name': 'Utah',
        'abbreviation': 'UT'
    },
    {
        'name': 'Vermont',
        'abbreviation': 'VT'
    },
    {
        'name': 'Virginia',
        'abbreviation': 'VA'
    },
    {
        'name': 'Washington',
        'abbreviation': 'WA'
    },
    {
        'name': 'West Virginia',
        'abbreviation': 'WV'
    },
    {
        'name': 'Wisconsin',
        'abbreviation': 'WI'
    },
    {
        'name': 'Wyoming',
        'abbreviation': 'WY'
    }
  ];


  constructor(private cartService: CartService, private fb: FormBuilder, private toastr: ToastrService, private ngxService: NgxUiLoaderService) {
    this.subscriptionCart = cartService.upcomingshoppingCartHandler.subscribe(response => {
        this.totallingCost = 0;
        this.currentCart = response;
        this.currentCart.forEach((obj, index) => {
            this.totallingCost = this.totallingCost + obj.totalPrice;
        });
        this.totallingCost.toFixed(2);
    });

   }

  ngOnInit(): void {

  }

  remove(removeMe) {
    this.ngxService.start();
    this.currentCart.forEach((obj, index) => {
        if(obj == removeMe) {
            this.currentCart.splice(index, 1);
        }
    });
    this.cartService.updateShoppingCart(
        this.currentCart
    );
    this.ngxService.stop();
  }

  emptycart() {
    this.ngxService.start();
    this.currentCart = [];
    this.cartService.updateShoppingCart(
        this.currentCart
    );
    this.ngxService.stop();
  }

  checkout() {
        // check if international
        if(this.addressForm.get('international').value) {
            //loop through
            this.totallingCost = 0;
            this.currentCart.forEach((item, index) => {
                // if not digital
                if (item.version !== 'Digital') {
                    // change shipping
                    const currentPrice = item.totalPrice;
                    const shippingPrice = item.sh;
                    // let
                    let tempTotal = (currentPrice - shippingPrice) + 40;
                    item.totalPrice = tempTotal;
                    item.sh = 40;
                }
            });
            this.updateTotal();
        }

    this.paypalFormatItems = [];
    this.showPaypal = true;
    this.status = 'paypal';
    let tempObj;
    let stringingPrice;
    let stringingQty;
    let preFormatting
    this.currentCart.forEach(element => {
        preFormatting = element.totalPrice / element.qty;
        let formattingPrice = preFormatting.toFixed(2);
        stringingPrice = formattingPrice.toString();
        stringingQty = element.qty.toString();
        tempObj = {
            name: element.bookname + ' - ' + element.version,
            quantity: stringingQty,
            category: 'DIGITAL_GOODS',
            unit_amount: {
                currency_code: 'USD',
                value: stringingPrice,
            }
        }
        this.paypalFormatItems.push(tempObj);
    });
    this.initConfig();
  }

  updateTotal() {
    this.ngxService.start();
    // ignore this if its digital..  get out
    this.totallingCost = 0;
    this.currentCart.forEach((obj, index) => {
        let calcShip = 0;
        // this.totallingCost = this.totallingCost + (obj.price * obj.qty);
        if (obj.sh) {
            calcShip = (obj.sh * obj.qty);
        }
        let calcCost = (obj.price * obj.qty);
        let finalCost = calcCost + calcShip;
        this.totallingCost = this.totallingCost + finalCost;
        obj.totalPrice = finalCost;
    });
    this.totallingCost.toFixed(2);
    this.ngxService.stop();
  }

  removeQuantities = async (cart: any[]) => {
    // this function will do the removing quanties from DB
    // loop through item product in DB
    // if product name is found in final purchased items
    // loop through the version of that product
    // if the version matches what was purchased
    // subtract the quantity from the inventoryTotal
    // send back the product list to DB


    const querySnapshot = await getDocs(collection(db, "productsfull"));
    querySnapshot.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        this.originalProductsArray[item.id] = item.products;
        this.currentCart.forEach((obj, index) => {
            if (obj.bookname === item.name && obj.version !== 'Digital') {
                let formedObj = {
                    id: item.id,
                    origQty: obj.inventoryCount,
                    name: obj.bookname,
                    purchaseQty: obj.qty,
                    hiddenName: obj.hiddenName,
                    products: item.products,
                    version: item.version
                };
                // loop through item.products (from DB)
                item.products.forEach((prod, i) => {
                    // then check for version to match from obj and looped array
                    if (obj.version === prod.version) {
                        // do quantiy count
                        let newQty = obj.inventoryCount - obj.qty;
                        // then save updated quantity
                        prod.inventoryCount = newQty;
                        // send to new function to update product
                        this.dbCall(item.id);
                    }
                });

            }

        });

    });
    this.currentCart = [];
    this.cartService.updateShoppingCart(
        this.currentCart
      );

      this.ngxService.stop();
  }

  initConfig(): void {
    const lengths = this.currentCart.length
    const lengthString = lengths.toString();
    const mathstart = this.totallingCost.toFixed(2);
    const currentValueString = mathstart.toString();
    this.cartBackup = [];
    this.payPalConfig = {
        currency: 'USD',
        clientId: environment.paypalkey,
        createOrderOnClient: (data) => < ICreateOrderRequest > {
            intent: 'CAPTURE',
            purchase_units: [{
                amount: {
                    currency_code: 'USD',
                    value: currentValueString,
                    breakdown: {
                        item_total: {
                            currency_code: 'USD',
                            value: currentValueString
                        }
                    }
                },
                items: this.paypalFormatItems
            }]
        },
        advanced: {
            commit: 'true'
        },
        style: {
            label: 'paypal',
            layout: 'vertical'
        },
        onApprove: (data, actions) => {
         this.ngxService.start();
          this.completed = true;

          this.paypalPurchaseInfo = data;
        },
        onClientAuthorization: (data) => {
            this.cartBackup = this.currentCart;
            this.status = 'success';
            this.formedDBPurchase = {
              customer_info: data,
              paypal_info: this.paypalPurchaseInfo,
              purchased_info: this.currentCart,
              shippingAddress: this.addressForm.value
            }
            this.displayDownloads(this.currentCart);

            addDoc(collection(db, 'purchases'), this.formedDBPurchase).then((result) => {
                // do the dance to remove all quanties from DB
                this.toastr.success('Order Complete!', 'Success', {
                    timeOut: 2000,
                });
                this.emailSuccess(this.cartBackup, mathstart);
            });
            this.removeQuantities(this.currentCart);
        },
        onCancel: (data, actions) => {
            this.showCancel = true;
        },
        onError: err => {
            console.log('OnError', err);
            this.showError = true;
        },
        onClick: (data, actions) => {
            console.log('onClick', data, actions);
        }
    };
  }

//   saveQtyinDb = async () => {
//     console.log('this.updateArray ', this.updateArray);
//     console.log('this.originalProductsArray ', this.originalProductsArray);
//     // loop through and modifiy the originalProductsArray
//     this.updateArray.forEach((obj, index) => {
//         this.dbCall()
//     });

//   }
  closingDownSuccess() {
    this.payPalConfig = {clientId: ''};
    this.status = 'cart';
    this.addressForm.reset();
  }

  emailSuccess(cart, totalPrice) {
    let formedHeader = '<div><h2>Thank you for your purchase. We are proud to have you as our loyal customer.</h2><p>You will receive shipping confirmation within 5-7 days. If you have any questions, feel free to contact us!</p></div>'
    let bodyHtml = '';
    let bodyheader = '<br><h3>Item(s) Purchased</h3><table><tr><th style="text-align: left;"><strong>Product</strong></th><th style="text-align: center;"><strong>Quantity</strong></th><th style="text-align: center;"><strong>Total Price</strong></th></tr>'
    let bodyender = '<tr><td style="text-align: left; color: red; font-weight: bold">Total Price:</td><td style="text-align: center;"></td><td style="text-align: center; color: red; font-weight: bold">$' + totalPrice + '</td></tr></table>'
    let downloadHeader = '<br><h3>Download Links</h3><table><tr ><th style="text-align: left;">Title</th><th style="text-align: left;">Download Link</th></tr>'
    let downloadBodyHtml = '';
    let downloadFooterHtml = '</table>';
    cart.forEach((obj, index) => {
        let formedHtmlAdd = ''
        formedHtmlAdd = '<tr><td style="text-align: left;">' + obj.bookname + ' - ' + obj.version + '</td><td style="text-align: center;">' + obj.qty + '</td><td style="text-align: center;">' + obj.totalPrice + '</td></tr>';
        // build html conditionally based on data..
        // add it to email
        bodyHtml = bodyHtml + formedHtmlAdd;


    });
    this.downloadsList.forEach((dl, index) => {
        let formedDLAdd = ''
        formedDLAdd = '<tr><td style="text-align: left;">' + dl.name + '</td><td style="text-align: left;"><a href="http://magneticforce.com/assets/pdf/' + dl.source + '" target="_blank">Click Here</a></td></tr>';
        // build html conditionally based on data..
        // add it to email
        downloadBodyHtml = downloadBodyHtml + formedDLAdd;
    });
    const formedBody = bodyheader + bodyHtml + bodyender;
    const formedDownloadTable = downloadHeader + downloadBodyHtml + downloadFooterHtml;
    this.successEmailHtml = formedHeader + formedBody + formedDownloadTable;
    addDoc(collection(db, 'emails'), {
        to: this.addressForm.get('emailAddress').value,
        bcc: 'info@magneticforce.com',
        message: {
          subject: 'Magnetic Force - Order Confirmed',
          text: 'Thank you for your purchase!',
          html: this.successEmailHtml,
        }
      }).then(() => console.log('Queued email for delivery!'));
  }

  displayDownloads(cart) {
    // loop through cart and find the names of the title
    cart.forEach((obj, index) => {
        // if digital
        if (obj.version === 'Digital') {
            digitalDownloads.forEach((ddl, i) => {
                if(obj.bookname === ddl.name) {
                    this.downloadsList.push(ddl);
                }

            });

        }
        // match it to an array of book links.. and display accordingly

    });

  }
  clearForm() {
    this.addressForm.reset();
  }
  addShipping() {
    // loop through cart and see if it contains something phsicyal
    let hasPhysical = false;
    this.currentCart.forEach((obj, index) => {
        if(
            obj.version === 'Physical' ||
            obj.version === 'Loyalty Package - Hardcover' ||
            obj.version === 'Loyalty Package - Softcover' ||
            obj.version === 'Hardcover' ||
            obj.version === 'Hardcover (Only)' ||
            obj.version === 'Softcover (Only)'
        ) {
            hasPhysical = true;
        }
    });
    if(hasPhysical) {
        this.status = 'shipping';
    } else {
        this.checkout();
    }
  }
  dbCall = async (productId) => {
    let currentdocRef = doc(db, "productsfull", productId);
    await updateDoc(currentdocRef, {
      products: this.originalProductsArray[productId]
    })
    .then((result) => {
    });
  }

}

