<section id="books" class="partners-area bg-161616 pt-100 pb-70 st-change">
    <div class="container">
        <div class="section-title farfle">
            <h2>Book Releases</h2>
            <!-- <p>High level description of the type of books we publish. Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="row">
            <div class="col-lg-9 col-sm-4 col-md-4 col-5 book-main">
                <img src="assets/img/sleeper-cover.gif" alt="image">
                <div class="book-container">
                    <h4><strong>SLEEPER</strong> <span> - The Sara Gray Chronicles Book 1</span></h4>
                    <p class="sub-title">Sara Gray is sixteen years old. She can speak many languages, ride a motorbike at high speed, and beat a trained opponent in hand-to-hand combat. Only she doesn’t know it ... yet.</p>
                    <p class="main-text">Sara is plagued by questions. Who has been sending her cryptic messages? How has she developed amazing new abilities? Why is she seeing someone else’s memories Finding the answers will lead her from the back streets of Rajasthan to the playground of Bollywood's elite, and force her to confront a terrifying possibility ... that her whole life has been a lie. </p>
                    <a href="#test-popup" class="popup-hawk">About the Author</a>
                    <div class="main-pg1">SLEEPER: THE SARA GRAY CHRONICLES BOOK 1 is a Young Adult novel  (Ages 12+).<br /> <span>It contains 206 pages (with approximately 30 full-color illustrations).</span></div>
                    <div class="price"><strong>Hardcover: </strong>$24.99US +S/H</div>
                    <div class="price"><strong>Softcover: </strong>$14.99US +S/H</div>
                    <div id="test-popup" class="white-popup mfp-hide">
                        <h3>LUKE C JACKSON</h3><br /><p class="darkergrey">Luke is the author of four Young Adult novels, a picture storybook, films, and games. He has taught high school English Literature in Melbourne, Australia for more than 15 years, and has lectured at university in a range of subjects, including Visual Narrative. In 2017, he completed a Ph.D. focusing on the role of a writer in creating narrative-driven digital games. Whatever the medium, he is always seeking ways to push the boundaries of storytelling. <a href="http://www.lukecjackson.com" target="_blank" style="color: blue">www.lukecjackson.com</a></p>
                    </div>
                </div>
            </div>



            <!-- <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img3.png" alt="image">
                    </a>
                </div>
            </div> -->

            <!-- <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img4.png" alt="image">
                    </a>
                </div>
            </div> -->


        </div>

        <div class="row big-margin">
            <div class="col-lg-9 col-sm-4 col-md-4 col-5 book-main">
                <img src="assets/img/godlings-cover.gif" alt="image">
                <div class="book-container">
                    <h4><strong>GODLINGS AND THE GATES OF CHAOS</strong></h4>
                    <p class="sub-title">Diana. Ra. Chaac. Dionysus. Tiamat. Nemesis. Their names are Legend.</p>
                    <p class="main-text">Gods and Goddesses, heroes of the ancient world, have been reborn. Once separated by oceans and centuries, they must form a new Pantheon, one capable of battling mythology’s most fearsome threats. </p>
                    <p class="main-text">These Godlings are all that stands between the new world and the Swarm, a primordial force that thrives on chaos, now unified behind a powerful leader.</p>
                    <p class="main-text">To succeed, the Godlings must embrace chaos itself.</p>
                    <a href="#test-popup" class="popup-hawk">About the Author</a>
                    <div class="main-pg1">GODLINGS AND THE GATES OF CHAOS is the first graphic novel in the Godlings universe. 96 pages in full color, plus character profiles and poster images.</div>
                    <div class="price"><strong>Hardcover: </strong>$24.99US +S/H</div>
                    <div class="price"><strong>Single Issues: </strong>$3.99US +S/H</div>
                </div>
            </div>
        </div>
        <div class="books-release-shape1"><img src="assets/img/nemesistshirt.png" alt="image" class="nemesis"></div>
    </div>
</section>
