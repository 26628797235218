<section id="contact" class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Contact</h2>
            <p>For additional information, please use the following contact information:</p>
        </div>

        <div class="row row-y">
            <div class="col-lg-6 col-md-6 address-holder">
                <strong>MAGNETIC FORCE, LLC</strong>
                <span>3500 West Olive Ave, Suite 300</span>
                <span>Burbank, CA 91505</span>
                <a href="mailto:info@magneticforce.com">info@magneticforce.com</a>
            </div>

            <div class="col-lg-6 col-md-6 address-holder">
                <span class="ital">MAGNETIC FORCE, LLC is a wholly-owned subsidiary of:</span>
                <strong>THE INVISIBLE HAND LIMITED LIABILITY COMPANY</strong>
                <span>468 North Camden Drive, Suite 200</span>
                <span>Beverly Hills, California 90210</span>
                <a href="http://www.theinvisiblehandllc.com" target="_blank">www.theinvisiblehandllc.com</a>
            </div>

        </div>
    </div>
</section>
