import { Component, OnInit } from '@angular/core';
import { db } from '../../../firebase';
import { collection, getDocs, addDoc } from "firebase/firestore";
import { CartService } from '../../../services/cart.service'
import { Subject, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

  fullProductArray: any[];
  fullProductArrayNew: any[];
  shoppingCart = [];
  shoppingCartCopy = [];
  shoppingCartBaseLine = [];
  subscriptionCart: Subscription;
  quants = [];

  productGroup = this.fb.group({
    qty: null
  });

  constructor(
    private cartService: CartService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    this.subscriptionCart = cartService.upcomingshoppingCartHandler.subscribe(response => {
        this.shoppingCartBaseLine = response;
        this.shoppingCartCopy = Object.assign([], this.shoppingCartBaseLine);
        // this.shoppingCartCopy = this.shoppingCartBaseLine;
        if (response.length == 0) {
            this.shoppingCart = [];
            this.shoppingCartCopy = [];
            this.shoppingCartBaseLine = [];
            this.quants = [];
        }
    });

  }

  gettingUserInfoFromFullList = async () => {
    let tempTokenHolderArray = [];
    let tempUserData = []
    let validHolder = false;

    getDocs(collection(db, 'productsfull'))
    .then((result) => {
      result.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        // item.sh = doc.sh.toFixed(2);
        tempTokenHolderArray.push(item);
      });
      this.fullProductArrayNew = tempTokenHolderArray;
      this.fullProductArrayNew.sort((a, b) => (a.order > b.order) ? 1 : -1);
    });

    // this was used to add all the docs.. please ignore
    // this.fullProductArrayNew.forEach((doc) => {
    //     console.log('doc ', doc);
    //     addDoc(collection(db, 'productsfull'), {
    //         name: doc.name,
    //         type: doc.type,
    //         size: doc.size,
    //         order: doc.order,
    //         created: new Date(),
    //         products: doc.products
    //       })
    //         .then((result) => {
    //           console.log('result', result);
    //       });
    // });

  };

  ngOnInit(): void {
    this.gettingUserInfoFromFullList();
  }

  addToCart(item, bookname) {
    let copyItem = item;
    let totalPrice = 0;
    let shippingPrice = 0;
    let stillMatching = false
    let matchingIndex = 0;
    console.log('item ', item);
    // check for digital item - cause we dont need to worry about shipping
    if(item.version === 'Digital') {
        copyItem.totalPrice = item.price;
        copyItem.qty = 1;
        let matching = false
        //check to see if it already exists in the shopping cart array?

        this.shoppingCartBaseLine?.forEach(element => {
            if(element.hiddenName === item.hiddenName && item.version === 'Digital') {
                matching = true;
            }
            // if() {

            // }
        });
        if(matching) {
            return this.toastr.error('Can only buy one digital copy of this title', 'Error', {
                timeOut: 2000,
              });
        }
    } else {

        // make sure they have qty selected.. if not fire error..
        if(!copyItem.qty || copyItem.qty === 0) {
            return this.toastr.error('Please select quantity', 'Error', {
                timeOut: 2000,
              });
        }

        // make sure there's enough inventory
        if(copyItem.qty > copyItem.inventoryCount) {
            return this.toastr.error('Selected too many items', 'Error', {
                timeOut: 2000,
              });
        }
        // console.log('copyItem.qty ', copyItem.qty);
        this.shoppingCartCopy?.forEach((element, index) => {
            if(element.hiddenName === item.hiddenName) {
                stillMatching = true;
                matchingIndex = index;
            }
        });

        // dont write new item..  check to see if it exists and update it..
        // as your updating.. make sure the qty isnt too much
        if (item.qty >= 1) {
            shippingPrice = 0;
            shippingPrice = item.sh * item.qty;
            let formingPrice = item.price * item.qty;
            copyItem.totalPrice = shippingPrice + formingPrice;
            // copyItem.totalPrice.toFixed(2);
        }
    }
    copyItem.bookname = bookname;
    // copyItem.totalPrice.toFixed(2);
    if(stillMatching) {
        copyItem.qty = this.quants[copyItem.hiddenName] + item.qty;
        this.shoppingCart[matchingIndex].qty = copyItem.qty;
        this.cartService.updateShoppingCart(
            this.shoppingCart
        );
        this.toastr.success('Quantity Updated', 'Success', {
            timeOut: 2000,
          });

        this.quants[item.hiddenName] = copyItem.qty;
        // loop through the array
        // if out the same item in the cart
        // add the quantities up
        // sent success message that we updated shit
    } else {
        // console.log('were elsed');
        this.shoppingCart.push(copyItem);
        this.quants[item.hiddenName] = copyItem.qty;
         // reassign that array though the service
        this.cartService.updateShoppingCart(
            this.shoppingCart
        );
        this.toastr.success('Added to cart!', 'Success', {
            timeOut: 2000,
          });
    }

  }


}
